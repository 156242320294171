@font-face {
  font-family: "proxima";
  src: url("./ProximaNova-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "proxima";
  src: url("./ProximaNova-Semibold.woff2") format("woff2");

  font-weight: bold;
  font-style: normal;
}
